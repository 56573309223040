/**
 * @license
 */
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//mode MARKETPLACE Category and options are global
//mode STORE Category and options are specific


// export const environment = {
//     production: true,
//     //GOOGLE MAP
//     googleApiKey: '',
//     //MARKETPLACE | BTB | STANDARD
//     mode: 'MARKETPLACE',
//     //API URL
//     apiUrl: "http://localhost:8080/api",
//     shippingApi: 'http://localhost:9090/shipping/api/v1',
//     client: {
//         language: {
//             default: 'en',
//             array: [
//                 'en',
//                 'np'
//             ],
//         },
//     }
// };


export const environment = {
    production: true,
    //GOOGLE MAP
    googleApiKey: window["env"]["APP_MAP_API_KEY"] || '',
    //MARKETPLACE | BTB | STANDARD
    mode: 'MARKETPLACE',
    //API URL
    apiUrl: window["env"]["APP_BASE_URL"] || 'https://search-nepal-backend-t6mxiar6na-uw.a.run.app/api/v1',
    shippingApi: window["env"]["APP_SHIPPING_URL"] || 'http://localhost:9090/api',
    client: {
        language: {
            default: window["env"]["APP_DEFAULT_LANGUAGE"] || 'en',
            array: [
                'en',
                'np'
            ],
        },
    }
};